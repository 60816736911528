<template>
  <div>
    <table>
      <tr v-for="(rx, eye) in rxPair" :key="eye">
        <td :class="eyeLabelClassComputed[eye]">
          <b class="mr-2">{{ eye.toUpperCase() }}</b>
        </td>
        <td :class="rxStringClass">
          <span v-if="rx.string == 'plano'">&nbsp;</span>{{ rx.string }}
        </td>
        <td class="pr-4"></td>
      </tr>
      <tr v-if="add && !noAdd">
        <td></td>
        <td class="addTd" :class="addClassComputed">
          <span class="mx-2">{{ add }} add</span>
          <span v-if="dominantEye" class="mx-2">
            ({{ dominantEye }} dominant)</span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import RxMethods from "@/assets/js/cls/RxMethods";
/*
NOTE: can also format keratometry (the rxPair .od/.os data just just needs a "string" property)
*/
export default {
  name: "RxFormatted",
  mixins: [RxMethods],
  props: {
    rxPair: null, // format {eye: {.., string:"", add: xxx}}
    eyeLabelColors: null, // if true will make "OD" green and "OS" blue
    rxStringClass: null,
    eyeLabelClass: null,
    addClass: null,
    noAdd: null, // if true will not show the add text
    noDominant: null, // if true will not show the dominant text
  },
  data: function () {
    return {};
  },
  methods: {},
  computed: {
    addClassComputed: function () {
      return this.addClass ? this.addClass : this.rxStringClass;
    },
    eyeLabelClassComputed: function () {
      let rtn = {};
      ["od", "os"].forEach((eye) => {
        let c = this.eyeLabelClass ? this.eyeLabelClass : this.textClass;
        if (this.eyeLabelColors) c += ` ${eye}TextColor`;
        rtn[eye] = c;
      });
      return rtn;
    },
    add: function () {
      let rx = this.rxPair;
      // if (!rx) return null;
      return rx?.od?.add || rx?.os?.add;
    },
    dominantEye: function () {
      if (this.noDominant) return null;
      let rx = this.rxPair;
      let rtn = "";
      if (rx?.os?.dominant) rtn = "OS";
      if (rx?.od?.dominant) rtn = "OD";
      return rtn;
    },
  },
  watch: {
    rxPair: {
      handler: function (val) {
        ["od", "os"].forEach((eye) => {
          if (val[eye]) {
            if (val[eye].sph && !val[eye].string) {
              val[eye].string = this.prettyString(val[eye]);
            } else if (val[eye].k1 && !val[eye].string) {
              // console.log(val[eye]);
              val[eye].string = this.prettyFullKstring(val[eye]);
            }
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
td,
th {
  text-align: left;
}
.addTd {
  text-align: right;
}
</style>
