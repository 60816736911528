<template>
  <div>
    <!-- {{ $vuetify.breakpoint.name }} -->
    <div v-if="!isMobile">
      <autocomplete-all-2
        class="flex-grow-1"
        :hideSubscriberContent="!isSubscribed"
        :hideModuleOptions="$vuetify.breakpoint.name == 'sm'"
        @dismiss="dialog = false"
        :canSearchAll="true"
      ></autocomplete-all-2>
    </div>
    <div v-else>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-2" color="primary" v-bind="attrs" v-on="on" text
            ><v-icon color="primary" class="mr-2">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="titleColor"
            >Search EyeDock <v-spacer></v-spacer
            ><v-btn text small @click="dialog = false" color="primary">
              Close</v-btn
            >
          </v-card-title>

          <div class="ma-4 py-4">
            <autocomplete-all-2
              class="flex-grow-1"
              :hideSubscriberContent="!isSubscribed"
              @dismiss="dialog = false"
              :canSearchAll="true"
            ></autocomplete-all-2>

            <div class="mt-4">
              <div class="overline">Example Searches:</div>
              <ul>
                <li v-for="(row, i) in examples" :key="i">
                  <b> {{ row.name }}</b>
                  <sup v-if="row.proFeature && !isSubscribed" class="red--text">
                    *</sup
                  >:
                  <ul>
                    <li>
                      <i>
                        <span
                          v-for="(item, index) in row.items"
                          :key="`${i}_${index}`"
                          class="mr-4"
                        >
                          <a
                            v-if="!row.proFeature || isSubscribed"
                            :href="item.url"
                            >{{ item.name }}</a
                          >
                          <span v-else>{{ item.name }}</span>

                          <span v-if="index < row.items.length - 1">, </span>
                        </span>
                      </i>
                    </li>
                  </ul>
                  <!-- <ul>
                    <li v-for="item in row.items" :key="item">"{{ item }}"</li>
                  </ul> -->
                </li>
              </ul>
            </div>
            <div class="mt-4 caption" v-if="!isSubscribed">
              <sup class="red--text">*</sup> EyeDock Pro feature
              <router-link
                to="/documentation/about-eyedock-pro"
                class="ml-4"
                @click="dialog = false"
                >About EyeDock Pro</router-link
              >
            </div>
          </div>
          <rx-paster
            :imageIcon="true"
            buttonText="Search"
            :params="['small', 'block', 'outlined']"
            :types="['rx', 'k']"
            :initiateSearchAll="true"
            instructions="Type or paste text that includes a refraction or keratometry. Your values can then be used for calculations and/or searches."
            class="my-4 mx-3"
            @quit="dialog = false"
          ></rx-paster>
          <!--    buttonText="Or, enter Rx/Ks for a search or calculation"
          buttonIcon="magnify" -->
          <div class="text-center"></div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import Name from "../dir/file";
import AutocompleteAll2 from "./AutocompleteAll-2.vue";
import RxPaster from "@/components/clStuff/RxPaster.vue";

export default {
  name: "AutocompletePopup",
  components: { AutocompleteAll2, RxPaster },
  props: {
    isSubscribed: null,
  },
  data: function () {
    return {
      dialog: false,
      examples: [
        {
          name: "Cls",
          items: [
            { name: "Oasys", url: "/cls/search?q=oasys" },
            { name: "Biofinity", url: "/cls/search?q=biofinity" },
            { name: "Alcon", url: "/cls/company/alcon" },
          ],
          proFeature: false,
        },
        {
          name: "Rxs",
          items: [
            {
              name: "-8.75 -3.25 x 017",
              url: "/cls/rx#refractionOD=-8.75-3.25x017",
            },
          ],
          proFeature: true,
        },
        {
          name: "Meds",
          items: [
            { name: "Xiidra", url: "/meds/details/xiidra" },
            { name: "Maxitrol", url: "/meds/details/maxitrol" },
            { name: "Systane Balance", url: "/meds/details/Systane Balance" },
          ],
          proFeature: false,
        },
        {
          name: "ICD10",
          items: [
            { name: "POAG", url: "/icd10/search/poag" },
            { name: "Asteroid", url: "/icd10/search/asteroid" },
            { name: "Chicken", url: "/icd10/search/chicken" },
          ],
          proFeature: true,
        },
        {
          name: "EM/CPT",
          items: [
            {
              name: "Punctal Plugs",
              url: "/billing-codes#category=punctal-plugs-cpt",
            },
            {
              name: "Foreign Body",
              url: "/billing-codes#category=foreign-body-removal-cpt",
            },
          ],
          proFeature: true,
        },
        {
          name: "Conditions",
          items: [
            {
              name: "Episcleritis",
              url: "/ocular-conditions/details/episcleritis",
            },
            {
              name: "Papilledema",
              url: "/ocular-conditions/details/papilledema",
            },
          ],
          proFeature: true,
        },
        {
          name: "Calcs",
          items: [
            { name: "Oblique", url: "/calcs/oblique" },
            { name: "Meridian power", url: "/calcs/meridian-power-calculator" },
          ],
          proFeature: true,
        },
      ],
    };
  },
  methods: {},
  computed: {
    isMobile: function () {
      return !this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>

<style scoped>
.titleColor {
  background-color: #ffd9a1;
}
</style>
