import ApiServices from "@/services/ApiServices";
// takes a search text and tries to find Rxs & Ks
// parent should have data: rxs, ks, rxAlternatives, kAlternatives, error, loading, kLoading
export default {
  data: () => ({
    rxParserApi: "/parseRx2?rxText=",
    kParserApi: "/parseK?kText=",
  }),
  methods: {
    parseTextSubmitted(what = ["rx"]) {
      if (what.includes("rx")) this.rxToParseSubmitted();
      if (what.includes("k")) this.kToParseSubmitted();
    },
    rxToParseSubmitted() {
      if (!this.searchText || this.searchText == "") return;
      this.loading = true;
      let encodedText = encodeURIComponent(this.searchText);
      ApiServices.genericApiCall(this.rxParserApi + encodedText)
        .then((response) => {
          let result = response.data;
          if (result.rxs && result.rxs.length > 0) this.rxs = result.rxs[0];
          if (this.rxs?.od && !this.noDominant) this.rxs.od.dominant = true;
          if (result.rxAlternatives)
            this.rxAlternatives = result.rxAlternatives;
          if (result.error) this.error = result.error;
        })
        .catch((error) => {
          console.log(error);
          this.error = "An API error occurred when parsing Rx";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    kToParseSubmitted() {
      if (!this.searchText || this.searchText == "") return;
      this.kLoading = true;
      this.kAlternatives = null;
      let url = this.kParserApi + encodeURIComponent(this.searchText);

      ApiServices.genericApiCall(url)
        .then((response) => {
          let result = response.data;
          if (result.error) {
            console.log(result.error);
          } else if (result.ks) {
            if (result.ks && result.ks.length > 0) this.ks = result.ks[0];
            if (result.kAlternatives) this.kAlternatives = result.kAlternatives;
          }
        })
        .catch((error) => {
          console.log(error);
          this.kError = "An API error occurred when parsing keratometry";
        })
        .finally(() => {
          this.kLoading = false;
        });
    },
  },
};
