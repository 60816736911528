<template>
  <div>
    <v-dialog v-model="dialog" width="500" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :icon="!buttonText && !imageForButton"
          :small="params.includes('small')"
          :x-small="params.includes('x-small')"
          :text="params.includes('text')"
          :outlined="params.includes('outlined')"
          :block="params.includes('block')"
          :color="buttonColor"
          depressed
          :rounded="params.includes('small')"
          v-bind="attrs"
          v-on="on"
        >
          <v-img
            v-if="imageForButton"
            :src="imageForButton"
            height="16"
            max-width="45"
            contain
          ></v-img>
          <v-icon
            color="primary"
            v-else-if="buttonIcon"
            :small="params.includes('small')"
            :x-small="params.includes('x-small')"
            :class="{ 'mr-1': buttonText }"
            >mdi-{{ buttonIcon }}</v-icon
          >
          {{ buttonText }}
        </v-btn>
      </template>

      <v-card min-height="500">
        <v-card-title class="orangeCardHeader">
          <div class="d-flex flex-row align-center">
            <v-img
              v-if="imageForButton"
              :src="imageForButton"
              class="mr-4"
              max-width="60"
              contain
            ></v-img>
            <div>{{ typesText }} Text Parser</div>
          </div>
          <v-spacer></v-spacer
          ><v-btn icon small @click="dialog = false"
            ><v-icon small color="primary">mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <!-- {{ rxs }} -->

        <v-card-text class="pt-4 darkerCardText">
          <div class="caption">
            <div v-if="instructions">
              {{ instructions }}
            </div>
            <div v-else>Type or paste your {{ typesText }} below</div>
          </div>
          <!-- {{ changedText }} -->

          <v-textarea
            class="mt-3"
            name="searchText"
            :placeholder="'Example: \n-2-1.25 x 180 -1.25-2x005 +2 add'"
            autofocus
            depressed
            outlined
            :loading="loading || kLoading"
            background-color="#edf7ff"
            rows="3"
            row-height="25"
            v-model="searchText"
            :disabled="loading"
            hide-details="auto"
            @input="changedText = true"
            @paste="didPaste()"
          >
            <!-- @focus="$event.target.select()" -->
          </v-textarea>
          <div class="d-flex flex-row my-1">
            <v-spacer></v-spacer>
            <v-btn
              x-small
              class="mr-1"
              depressed
              @click="
                searchText = '';
                rxs = null;
                ks = null;
                error = null;
              "
              outlined
              :disabled="!searchText || searchText.length < 1"
              >Clear</v-btn
            >
            <v-btn
              :disabled="!searchText || searchText.length < 1 || !changedText"
              x-small
              class=""
              depressed
              @click="doSubmit()"
              color="primary"
              >Find {{ typesText }}</v-btn
            >
          </div>

          <v-fade-transition mode="out-in">
            <v-card v-if="!rxs && !ks" class="my-6 pa-2 infoBox" flat>
              <div>
                Paste or type your {{ typesText }} into the box above. I'll do
                my best to interpret it. <br /><br />If I get it wrong, try
                reformatting the text. Are the numbers in a weird order? Is
                there any extra information (PDs, VAs, prism) that might be
                confusing me?
              </div>
            </v-card>

            <div v-else-if="rxs || ks">
              <!-- :class="{ opacity0: !(rxs || ks) || changedText }" -->
              <table class="mx-auto mb-4">
                <tr v-if="rxs">
                  <td>
                    <div class="mt-4 d-flex flex-row align-center">
                      <div class="overline">Rxs</div>
                      <!-- <v-spacer></v-spacer> -->
                      <rx-parser-alternatives
                        v-if="rxAlternatives"
                        class="mx-1 pb-1"
                        :rxAlternatives="rxAlternatives"
                        btnText="wrong Rx?"
                        @newRxs="alternativeRxSelected($event)"
                      ></rx-parser-alternatives>
                    </div>
                    <rx-formatted
                      :rxPair="rxs"
                      :eyeLabelColors="true"
                      rxStringClass="embiggen grey--text text--darken-3"
                      eyeLabelClass="embiggen"
                      addClass="font-weight-medium"
                      :class="{ faded: !useRx_cbx }"
                      :noAdd="noAdd"
                      :noDominant="noDominant"
                    ></rx-formatted>
                  </td>
                  <td v-if="foundRxAndK">
                    <v-checkbox
                      v-if="foundRxAndK"
                      v-model="useRx_cbx"
                      color="green"
                      class="ml-4 mb-1"
                      :hide-details="true"
                      dense
                    >
                    </v-checkbox>
                  </td>
                </tr>
                <tr v-if="ks">
                  <td>
                    <div class="mt-4 d-flex flex-row align-center">
                      <div class="overline">Keratometry</div>
                      <!-- <v-spacer></v-spacer> -->
                      <k-parser-alternatives
                        v-if="kAlternatives"
                        class="mx-1 pb-1"
                        :kAlternatives="kAlternatives"
                        btnText="wrong Ks?"
                        @newKs="alternativeKsSelected($event)"
                      ></k-parser-alternatives>
                    </div>
                    <rx-formatted
                      :rxPair="ks"
                      :eyeLabelColors="true"
                      rxStringClass="embiggen grey--text text--darken-3"
                      eyeLabelClass="embiggen"
                      addClass="font-weight-medium"
                      :noAdd="noAdd"
                      :noDominant="noDominant"
                      :class="{ faded: !useK_cbx }"
                    ></rx-formatted>
                  </td>
                  <td v-if="foundRxAndK">
                    <v-checkbox
                      v-if="foundRxAndK"
                      v-model="useK_cbx"
                      color="green"
                      class="ml-4 mb-1"
                      :hide-details="true"
                      dense
                    >
                    </v-checkbox>
                  </td>
                </tr>
              </table>
            </div>
          </v-fade-transition>

          <v-alert v-if="error || kError">{{ error }} {{ kError }}</v-alert>
        </v-card-text>

        <v-divider class="mt-6"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" small depressed @click="dialog = false">Cancel</v-btn>
          <v-btn
            v-for="option in rxReturnOptions"
            :key="option.value"
            color="primary lighten-2"
            small
            depressed
            :disabled="!canSubmit"
            @click="submitAndClose(option.value)"
            >{{ option.label }}
          </v-btn>
          <v-btn
            v-if="!rxReturnOptions"
            color="green lighten-2"
            small
            depressed
            :disabled="!canSubmit"
            @click="submitAndClose()"
          >
            <span v-if="rxAndKSelected">Use selected items</span>
            <span v-else-if="rxs && useRx_cbx">Use Rx</span>
            <span v-else-if="ks && useK_cbx">Use Keratometry</span>
            <span v-else>Use {{ typesText }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* 
emits @pasted: 
{rx:{od:{obj}}, {os:{obj}}
or
{ks:{od:{obj}}, {os:{obj}}
or custom keys (i.e 'or' or 'cl') if rxReturnOptions is passed
*/
import RxMethods from "@/assets/js/cls/RxMethods";
import ApiServices from "@/services/ApiServices";
import StyleUtilities from "@/assets/js/StyleUtilities";
import RxFormatted from "@/components/clStuff/RxFormatted";
import RxTextParserMixin from "@/assets/js/cls/RxTextParserMixin";

export default {
  name: "RxPaster",
  components: {
    RxFormatted,
    RxParserAlternatives: () =>
      import("@/components/clStuff/RxParserAlternatives"),
    KParserAlternatives: () =>
      import("@/components/clStuff/KParserAlternatives"),
  },
  mixins: [RxMethods, ApiServices, StyleUtilities, RxTextParserMixin],
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    buttonColor: {
      type: String,
      default: "primary",
    },
    buttonOutline: null,
    buttonText: {
      type: String,
      default: "Rx/K Parser",
    },
    buttonIcon: {
      type: String,
      default: "text-search-variant",
    },
    imageIcon: null,
    noAdd: null,
    noDominant: null,
    types: {
      type: Array,
      default: () => ["rx"], // 'rx', 'k'
    },
    params: {
      type: Array,
      default: () => [],
    },

    rxReturnOptions: null, // an array of objects. lets the user select what the rx is for. for example, if an rx can be an OR or CL [{label:"Contact Lens Power", value:"cl"}, {label:"Over Refraction", value:"or"}]
    initiateSearchAll: null,
    instructions: null,
  },

  data: function () {
    return {
      dialog: false,
      searchText: "",
      loading: false,
      kLoading: false,
      rxs: null, // will be an array of objects, [{od:..., os:...}, {od:..., os:...}]
      rxAlternatives: null,
      changedText: false,
      error: null,
      selectedRx: null,
      // api: "/parseRx2?rxText=",
      // kApi: "/parseK?kText=",
      ks: null,
      kAlternatives: null,
      kError: null,
      useRx_cbx: true,
      useK_cbx: true,
      icon: "https://www.eyedock.com/images/menu_icons/rx_search_icon.svg",
      iconImgRoot: "https://www.eyedock.com/images/menu_icons/",
      rxSearchImage: "rx_search_icon.svg",
      rxkSearchImage: "rx_k_search_icon.svg",
      kSearchImage: "k_search_icon.svg",
    };
  },
  methods: {
    submitAndClose(type = null) {
      //   if (type) console.log(type);
      if (this.initiateSearchAll) this.doSearchAll();
      this.dialog = false;
      let rtn = {};
      //   console.log("submitting", this.rxs.od.add);
      //   let rxClone = this.getClone(this.rxs);
      //   let kClone = this.getClone(this.ks);
      // console.log("cloned", rxClone);
      if (type && this.rxs && this.useRx_cbx) rtn[type] = this.rxs;
      if (!type && this.rxs && this.useRx_cbx) rtn.rx = this.rxs;
      if (this.ks && this.useK_cbx) rtn.ks = this.ks;
      //   console.log("emitting", rtn.rx.os.add);
      //   rtn.rx.od.add = 2;
      this.$emit("pasted", rtn);
      this.dialog = false;
    },
    doSearchAll() {
      let q = "";
      if (this.rxs) q += encodeURIComponent(this.prettyStringOU(this.rxs));
      if (this.ks)
        q += " " + encodeURIComponent(this.prettyFullStringOU(this.ks));
      this.$emit("quit");
      this.$router.push({
        name: "SearchAll",
        query: { q: q },
      });
    },
    alternativeRxSelected(obj) {
      //   console.log(obj);
      this.rxs = this.getClone(obj);
    },
    alternativeKsSelected(obj) {
      //   console.log(obj);
      this.ks = this.getClone(obj);
    },
    getClone(obj) {
      //   console.log("cloning string", JSON.stringify(obj));
      //   console.log("cloned object", JSON.parse(JSON.stringify(obj)));
      let clone = JSON.parse(JSON.stringify(obj));
      if (obj.add) clone.add = parseInt(obj.add);
      return clone;
    },
    didPaste() {
      //   console.log("did paste");
      // run the next line afer 500ms
      setTimeout(() => {
        // this.$nextTick(() => {
        this.doSubmit();
        // });
      }, 300);
    },
    doSubmit() {
      //   console.log("dosubmit");
      this.error = null;
      this.kError = null;
      this.changedText = false;
      this.rxAlternatives = null;
      this.kAlternatives = null;
      this.useRx_cbx = true;
      this.useK_cbx = true;
      if (this.types.includes("rx")) this.rxToParseSubmitted(); //this.rxToParseSubmitted();
      if (this.types.includes("k")) this.kToParseSubmitted(); //this.kToParseSubmitted();
    },
  },
  computed: {
    foundRxAndK: function () {
      return this.rxs != null && this.ks != null;
    },
    typesText: function () {
      let hasRx = this.types.includes("rx");
      let hasK = this.types.includes("k");
      let rx = hasRx ? "Rx" : "";
      let k = hasK ? "Keratometry" : "";
      let joiner = hasRx && hasK ? "/" : " ";
      return rx + joiner + k;
    },
    rxAndKSelected: function () {
      return this.useRx_cbx && this.useK_cbx && this.rxs && this.ks;
    },
    canSubmit: function () {
      let textChanged = this.changedText;
      let hasItem = this.rxs || this.ks;
      let hasSelection = this.useRx_cbx || this.useK_cbx;
      return hasItem && hasSelection && !textChanged;
    },
    imageForButton: function () {
      if (!this.imageIcon) return null;
      let img = null;
      if (this.types.includes("rx")) img = this.rxSearchImage;
      if (this.types.includes("k")) img = this.kSearchImage;
      if (this.types.includes("rx") && this.types.includes("k"))
        img = this.rxkSearchImage;
      return this.iconImgRoot + img;
    },
    // buttonStyle: function () {
    //   let rtn = null;
    //   if (this.buttonOutline)
    //     rtn = "border: 1px solid " + this.buttonOutline + " important;";
    //   return rtn;
    // },
  },
  watch: {
    // types: {
    //   handler: function () {
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style scoped>
.faded {
  opacity: 0.5;
}
.opacity0 {
  opacity: 0;
}
</style>
