// -------RxHelper utilities---------
export default {
  methods: {
    // sets rxObject's properties to the props described in parameterObj
    // if any props not set (and don't already exist), uses defaults
    setRxObjectParameters: function (rxObject, parameterObj) {
      if (!rxObject) rxObject = {};
      if (!parameterObj) parameterObj = {};
      let parameterDefaults = {};
      parameterDefaults.sph = 0;
      parameterDefaults.cyl = 0;
      parameterDefaults.axis = 180;
      parameterDefaults.add = 0;
      parameterDefaults.round = 0.25;
      parameterDefaults.vertex = 0.012;
      ["sph", "cyl", "add", "round", "vertex"].forEach((key) => {
        if (parameterObj[key]) {
          parameterObj[key] = parseFloat(parameterObj[key]);
        }
      });
      if (parameterObj.axis) parameterObj.axis = parseInt(parameterObj.axis);

      let mergedObject = { ...parameterDefaults, ...rxObject, ...parameterObj };
      return mergedObject;
    },

    // convenience function
    newRxObject: function () {
      return this.setRxObjectParameters();
    },

    rxObjectFromSphCylAxis: function (sph, cyl, axis) {
      return this.setRxObjectParameters(null, {
        sph: sph,
        cyl: cyl,
        axis: axis,
      });
    },

    parseRxString: function (rxString) {
      if (!rxString) return null;
      let rxObject = this.newRxObject();
      if (!isNaN(rxString)) rxString = rxString.toString();
      if (!rxString || rxString == "") return rxObject;

      // console.log(rxString);
      rxString = rxString.trim().toLowerCase();
      rxString = rxString.replace(/pl(ano)?/, "0"); // replace plano with 0
      rxString = rxString.replace(/([a-z]{2,})/g, " "); // remove all remaining words
      rxString = rxString.replace(/\(?20\/\)?\s?\d\d\d?/g, ""); // replace any acuity info
      rxString = rxString.replace(/\s{2,}/g, " "); // get rid of excessive spaces
      rxString = rxString.replace(/[+\s]{2,}/g, "+"); // consolidate repeating plus signs ( -4.00++3.25..)
      rxString = rxString.replace(/[+\s]+-/g, "-"); // interpret a plus and minus as a minus (-4+-2x23)
      rxString = rxString.replace(/([+-])/g, " $1"); // put a space before any signs
      rxString = rxString.replace(/([+-])\s*/g, "$1"); // remove spaces after signs
      rxString = rxString.replace(/x/, " "); // replace "x" with a space
      rxString = rxString.replace(/\*/, " "); // replace "x" with a space
      rxString = rxString.replace(/[-+]$/gim, ""); // get rid of trailing "+" or "-"

      // replace all non-numbers (nums, signs, decimals) and spaces with nothing.
      rxString = rxString
        .replace(/[^\s.\-+\d]/g, "")
        .trim()
        .replace(/\s{2,}/g, " ");

      // console.log(rxString);

      let completeRegEx = /([-+\d.]+)\s([-+\d.]+)\s(\d+)\s?([+\d.]+)?/; // has sph, cyl, and axis
      let incompleteRegEx = /([-+\d.]{1,6})\s([-+\d.]{1,6})\s?/; // has sph and cyl

      let match = completeRegEx.exec(rxString);
      let incompleteMatch = incompleteRegEx.exec(rxString);
      if (match) {
        rxObject.sph = parseFloat(this.fixDiopters(match[1]));
        rxObject.cyl = parseFloat(this.fixDiopters(match[2]));
        rxObject.axis = parseInt(match[3]);
      } else if (incompleteMatch) {
        rxObject.sph = parseFloat(this.fixDiopters(incompleteMatch[1]));
        rxObject.cyl = parseFloat(this.fixDiopters(incompleteMatch[2]));
      } else if (!isNaN(parseFloat(rxString)) && isFinite(rxString)) {
        rxObject.sph = parseFloat(this.fixDiopters(parseFloat(rxString)));
      }
      return rxObject;
    },

    prettyString: function (rxObject, round = 0.25) {
      if (!rxObject || Object.keys(rxObject).length === 0) return "";
      // console.log(rxObject);
      return this.prettyRxString(
        rxObject.sph,
        rxObject.cyl,
        rxObject.axis,
        round
      );
    },

    prettyStringFromString: function (rxString, round = 0.25) {
      let rxObject = this.parseRxString(rxString);
      return this.prettyString(rxObject, round);
    },

    prettyRxString: function (sph, cyl, axis, round) {
      axis = this.fixAxis(axis);
      // console.log(round);
      if (!round || round < 0) round = 0.25;
      if (!cyl) cyl = 0;
      if (!sph) sph = 0;
      if (!axis) axis = 180;

      let sphString = this.numToDiopterString(sph, 1, round);
      let cylString = this.numToDiopterString(cyl, 1, round);
      let axisString = " x " + this.numToAxisString(axis);

      if (Math.abs(sph) < round / 2) sphString = "plano";
      if (Math.abs(sph) < round / 2 && Math.abs(cyl) < round / 2)
        return "plano";
      if (isNaN(sph)) sphString = "plano";
      // console.log("cyl " + cyl);
      // console.log("r " + (round));
      if (Math.abs(cyl) < round / 2) {
        if (sphString === "plano") return "plano";
        cylString = "sph";
        axisString = "";
      }
      let str = sphString + " " + cylString + axisString;
      // console.log(str);
      return str;
    },

    prettyStringPlus: function (rxObject, round = 0.25) {
      let paramsObj = this.parameterObjectForCylType(rxObject, 1);
      return this.prettyString(paramsObj, round);
    },

    prettyStringMinus: function (rxObject, round = 0.25) {
      let paramsObj = this.parameterObjectForCylType(rxObject, -1);
      return this.prettyString(paramsObj, round);
    },

    prettyStringOfType: function (rxObject, type, round = 0.25) {
      let paramsObj = this.parameterObjectForCylType(rxObject, type);
      return this.prettyString(paramsObj, round);
    },
    prettyStringOU: function (rxObjects) {
      let odString = this.prettyString(rxObjects.od);
      let osString = this.prettyString(rxObjects.os);
      let addString = this.prettyAdd(rxObjects.od || rxObjects.os);
      return odString + " " + osString + " " + addString;
    },
    prettyAdd: function (rxObject) {
      let add = null;
      if (rxObject.add && rxObject.add > 0)
        add = this.numToDiopterString(rxObject.add, 1);
      return add;
    },

    numToDiopterString: function (aNum, showSign, round) {
      // console.log("numToDiopterString: " + aNum);
      if (!round) round = 0.01;
      if (aNum === 0) {
        //do nothing
      } else {
        if (isNaN(aNum) || !aNum) return undefined;
      }
      if (aNum >= 100 || aNum <= -100) aNum = aNum / 100;
      let roundTo = 1 / round;
      aNum = Math.round(roundTo * aNum) / roundTo;
      let sign = showSign && aNum > 0 ? "+" : "";
      return sign + aNum.toFixed(2);
    },

    numToAxisString: function (aNum) {
      if (isNaN(aNum) || !aNum) return undefined;
      let s = Math.round(aNum) + "";
      if (s === 0) s = 180;
      while (s.length < 3) s = "0" + s;
      return s;
    },

    fixAxis: function (axis) {
      while (axis < 0.5) {
        axis += 180;
      }
      while (axis > 180) {
        axis -= 180;
      }
      return axis;
    },

    fixDiopters: function (aNum) {
      while (Math.abs(aNum) >= 30) aNum /= 10;
      return aNum;
    },

    diffVertex: function (rxObject, vertexTo, vertexFrom) {
      if (!vertexFrom) vertexFrom = 0.012;
      if (!vertexTo) vertexTo = 0;
      let oldSph = rxObject.sph;
      let oldCyl = parseFloat(rxObject.sph) + parseFloat(rxObject.cyl);
      let newSph = 1 / (1 / oldSph - (vertexFrom - vertexTo));
      let newCyl = 1 / (1 / oldCyl - (vertexFrom - vertexTo));

      let vertexedRx = this.newRxObject();
      vertexedRx.vertex = vertexTo;
      vertexedRx.sph = newSph;
      vertexedRx.cyl = newCyl - newSph;
      vertexedRx.axis = rxObject.axis;
      return vertexedRx;
    },

    // note this returns a number (not an object or string)
    sphericalEquivalent: function (rxObject) {
      return 0.5 * rxObject.cyl + +rxObject.sph;
    },
    // this will reduce the cyl to the maxCyl and adjust the axis to maintain the spherical equivalent
    // note returns an RxObject
    rxWithMaxCyl: function (rxObject, maxCyl = 0) {
      // if maxCyl is greater than the cyl of the rxObject we don't need to make an adjustment
      if (Math.abs(maxCyl) > Math.abs(rxObject.cyl)) return rxObject;
      let cylType = rxObject.cyl > 0 ? "1" : "-1";
      maxCyl = Math.abs(maxCyl) * cylType; // just in case the maxCyl isn't specified as a with the same cyl type as the rxObject
      let cylDiff = rxObject.cyl - maxCyl;
      let newSph = rxObject.sph + cylDiff * 0.5;
      return this.rxObjectFromSphCylAxis(newSph, maxCyl, rxObject.axis);
    },

    parameterObjectForCylType: function (rxObject, cylType) {
      let rtnObj = {};
      let transpose = false;
      if (!rxObject) return null;
      // if we want plus cyl and were given minus cyl
      if ((cylType === "plus" || cylType >= 0) && rxObject.cyl < 0)
        transpose = true;
      // if we want minus cyl and were given plus cyl
      if ((cylType === "minus" || cylType < 0) && rxObject.cyl > 0)
        transpose = true;

      if (transpose) {
        rtnObj.sph = +rxObject.sph + +rxObject.cyl;
        rtnObj.cyl = -rxObject.cyl;
        rtnObj.axis = this.fixAxis(rxObject.axis - 90);
      } else {
        // change nothing
        rtnObj.sph = rxObject.sph;
        rtnObj.cyl = rxObject.cyl;
        rtnObj.axis = rxObject.axis;
      }
      return rtnObj;
    },

    isValidRx: function (rxObject) {
      if (!rxObject) return false;
      let checkSph = this.checkSphereRange(rxObject.sph);
      let checkCyl = this.checkCylinderRange(rxObject.cyl);
      let checkAxis = this.checkAxisRange(rxObject.axis);
      return checkSph && checkCyl && checkAxis;
    },

    isPlano: function (rxObject) {
      if (!rxObject?.sph && !rxObject.cyl) return true;
      // console.log('is Plano:' + rxObject.sph + " && " + rxObject.cyl);
      return !parseFloat(rxObject.sph) && !parseFloat(rxObject.cyl);
    },

    checkSphereRange: function (aNum) {
      return aNum >= -30 && aNum <= 30;
    },

    checkCylinderRange: function (aNum) {
      return aNum >= -15 && aNum <= +15;
    },

    checkAxisRange: function (aNum) {
      if (isNaN(aNum)) return false;
      return aNum >= 0 && aNum <= 180;
    },

    rxIsSpherical: function (rxObject) {
      return !rxObject?.cyl || parseFloat(rxObject.cyl) == 0;
    },

    isWTR: function (rxObject) {
      let axisM = this.axisM(rxObject);
      return axisM <= 30 || axisM >= 150;
    },

    isATR: function (rxObject) {
      let axisM = this.axisM(rxObject);
      return axisM >= 60 && axisM <= 120;
    },

    isOblique: function (rxObject) {
      let axisP = this.axisP(rxObject);
      return (axisP > 30 && axisP < 60) || (axisP > 120 && axisP < 150);
    },

    cylOrientationType: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return "Spherical";
      let rtn = "Oblique";
      if (this.isATR(rxObject)) rtn = "ATR";
      if (this.isWTR(rxObject)) rtn = "WTR";
      return rtn;
    },

    axisP: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return 180;
      let axis = parseInt(rxObject.axis);
      return parseFloat(rxObject.cyl) > 0 ? axis : this.fixAxis(axis + 90);
    },
    axisM: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return 180;
      let axis = parseInt(rxObject.axis);
      return parseFloat(rxObject.cyl) < 0 ? axis : this.fixAxis(axis + 90);
    },

    cylP: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return 0;
      let cyl = parseFloat(rxObject.cyl);
      return cyl > 0 ? cyl : -cyl;
    },
    cylM: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return 0;
      let cyl = parseFloat(rxObject.cyl);
      return cyl < 0 ? cyl : -cyl;
    },

    sphP: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return rxObject.sph;
      let sph = parseFloat(rxObject.sph);
      let cyl = parseFloat(rxObject.cyl);
      return cyl > 0 ? sph : sph + cyl;
    },
    sphM: function (rxObject) {
      if (this.rxIsSpherical(rxObject)) return rxObject.sph;
      let sph = parseFloat(rxObject.sph);
      let cyl = parseFloat(rxObject.cyl);
      return cyl < 0 ? sph : sph + cyl;
    },

    verticalMeridian: function (rxObject) {
      let axis = this.axisP(rxObject);
      let axis2 = this.fixAxis(axis - 90);
      return axis >= 45 && axis < 135 ? axis : axis2;
    },

    horizontalMeridian: function (rxObject) {
      return this.fixAxis(this.verticalMeridian(rxObject) - 90);
    },

    verticalPower: function (rxObject) {
      // console.log(rxObject);
      let axis = this.axisP(rxObject);
      if (axis >= 45 && axis < 135) return this.sphP(rxObject);
      return +this.sphP(rxObject) + +this.cylP(rxObject);
    },

    horizontalPower: function (rxObject) {
      let axis = this.axisP(rxObject);
      if (axis >= 45 && axis < 135)
        return +this.sphP(rxObject) + +this.cylP(rxObject);
      return this.sphP(rxObject);
    },

    powerAtMeridian: function (rxObject, meridian) {
      if (!rxObject) return 0;
      let angleDiff = parseInt(rxObject.axis) - meridian;
      let radDiff = this.radians(angleDiff);
      let sinOfDiff = Math.sin(radDiff);
      return (
        parseFloat(rxObject.sph) +
        parseFloat(rxObject.cyl * Math.pow(sinOfDiff, 2))
      );
    },

    // the difference between the two axes
    // handles things like 001 & 179 are 2 deg apart
    smallestAxisDifference: function (axis1, axis2) {
      axis1 = this.fixAxis(axis1);
      axis2 = this.fixAxis(axis2);
      let diff = ((axis2 - axis1 + 90) % 180) - 90;
      return diff < -90 ? diff + 180 : diff;
    },

    prenticeRule: function (rxObject, decentration, meridian = 0) {
      // decentration is in cm!
      let power = this.powerAtMeridian(rxObject, meridian);
      return decentration * power;
    },

    // takes the uncorrected Rx and the dioptric demand for the target
    // calculates how much accom is needed to bring the closest meridian into focus
    accomNeededForTarget(rxObject, targetDiopters) {
      let targetRx = this.setRxObjectParameters(null, { sph: targetDiopters });
      let rx = this.addRx(rxObject, targetRx);
      // console.log(rx);
      let leastPlus = Math.min(rx.sph, rx.sph + rx.cyl);
      return leastPlus;
    },

    // takes an object with RxObjects (od & os), the target focal length in diopters, and the patient's maximum amount of accommodation (in diopters).
    // Calculates the amount of accommodation required for each eye
    // if yoked, uses the least amount of accommodation required for the better seeing eye
    // returns an object with the residual Rx after accommodation for each eye and the amount of accommodation required
    rxAfterAccommodation: function (
      rxObjects,
      target,
      maxAccom = 4,
      yoked = true
    ) {
      let rtn = {};
      let targetRx = this.setRxObjectParameters(null, { sph: target });
      let rxPlusTarget = {};
      let accomForEye = {};
      let bestVA = 99999;
      let eyeWithBestVA = "od"; // od or os
      let eyes = Object.keys(rxObjects); // should be od and os
      eyes.forEach((eye) => {
        let rx = this.addRx(rxObjects[eye], targetRx);
        rxPlusTarget[eye] = rx;
        // mostPlus is the amount of accommodation required for that target (accommodating until the closest meridian is in focus)
        let leastPlus = Math.min(rx.sph, rx.sph + rx.cyl);
        // console.log(leastPlus);
        let eyeAccom = Math.max(0, Math.min(maxAccom, leastPlus)); // the least plus value
        accomForEye[eye] = eyeAccom;
        let accomRx = this.setRxObjectParameters(null, { sph: eyeAccom });
        let finalRx = this.subtractRx(rx, accomRx);
        let vaWithAccom = this.VAFromCorrection(finalRx);
        let vaEqualButLessAccom =
          vaWithAccom == bestVA && eyeAccom < accomForEye[eyeWithBestVA];
        if (vaWithAccom < bestVA || vaEqualButLessAccom) {
          bestVA = vaWithAccom;
          eyeWithBestVA = eye;
        }
      });
      // Get the smallest value in accom
      // let yokedAccom = Math.min(accomForEye.od, accomForEye.os); // the least amount of accommodation required for both eyes (and not more than maxAccom)

      eyes.forEach((eye) => {
        let eyeAccom = yoked ? accomForEye[eyeWithBestVA] : accomForEye[eye];
        // eyeAccom = Math.max(0, eyeAccom);
        let accomRx = this.setRxObjectParameters(null, { sph: eyeAccom });
        let finalRx = this.subtractRx(rxPlusTarget[eye], accomRx);
        rtn[eye] = { rx: finalRx, accommodation: eyeAccom };
      });

      return rtn;
    },

    rxHash: function (rxObject, eye, includeAdd = true, rxKey = "refraction") {
      if (!rxObject) return "";
      if (!eye) eye = "";
      let rx = parseFloat(rxObject.sph).toFixed(2);
      rx +=
        (rxObject.cyl >= 0 ? "+" : "") + parseFloat(rxObject.cyl).toFixed(2);
      rx += "x" + parseInt(rxObject.axis);
      let str = rxKey + eye.toUpperCase() + "=" + encodeURIComponent(rx);

      // only include add info if add is a number
      if (includeAdd && rxObject.add && !isNaN(rxObject.add)) {
        str +=
          "&isDominant" +
          eye.toUpperCase() +
          "=" +
          (rxObject.dominant ? "yes" : "no");
        str += "&add" + eye.toUpperCase() + "=" + rxObject.add;
      }
      if (rxObject.vertex === 0 || rxObject.vertex) {
        str += "&vertex=" + rxObject.vertex;
      }
      return str;
    },
    rxHashOU: function (rxObjects, rxKey = "refraction") {
      // console.log(rxObjects);
      let rtn = [];
      let od = null;
      let os = null;
      if (rxObjects.od) od = this.rxHash(rxObjects.od, "od", false, rxKey);
      if (rxObjects.os) os = this.rxHash(rxObjects.os, "os", false, rxKey);

      if (!od && !os) return "";
      if (od) rtn.push(od);
      if (os) rtn.push(os);
      let str = rtn.join("&");

      // add the add stuff
      let add = null;
      if (rxObjects.od?.add && !isNaN(rxObjects.od.add)) {
        add = rxObjects.od.add;
      } else if (rxObjects.os?.add && !isNaN(rxObjects.os.add)) {
        add = rxObjects.os.add;
      }
      if (add) {
        str += "&add=" + add;
        let dominant = rxObjects.os?.dominant ? "OS" : "OD";
        str += `&dominant${dominant}=1`;
      }

      if (rxObjects.od?.vertex === 0 || rxObjects.od?.vertex) {
        str += "&vertex=" + rxObjects.od.vertex;
      }
      //console.log(str);
      return str;
    },

    // the inverse of the above function
    // takes hashparts (hashParserMixin) and returns RxObjects
    rxsFromHash: function (hashParts, rxKey = "refraction") {
      // console.log(rxKey);
      let odObj = null;
      let osObj = null;
      if (!hashParts) return null;
      if (hashParts[rxKey + "OD"])
        odObj = this.parseRxString(hashParts[rxKey + "OD"]);
      if (hashParts[rxKey + "OS"])
        osObj = this.parseRxString(hashParts[rxKey + "OS"]);
      let add = null;
      if (hashParts.addOD) {
        add = hashParts.addOD;
      } else if (hashParts.addOS) {
        add = hashParts.addOS;
      } else if (hashParts.add) {
        add = hashParts.add;
      }

      if (hashParts.vertex) {
        let vertex = parseFloat(hashParts.vertex);
        if (odObj) odObj.vertex = vertex;
        if (osObj) osObj.vertex = vertex;
      }

      if (add) {
        if (odObj) {
          odObj.add = add;
          if (hashParts.isDominantOD == "yes") odObj.dominant = 1;
          if (hashParts.dominant == "OD") odObj.dominant = 1;
          if (hashParts.dominantOD == "1") odObj.dominant = 1;
        }
        if (osObj) {
          osObj.add = add;
          if (hashParts.isDominantOS == "yes") osObj.dominant = 1;

          if (hashParts.dominant == "OS") osObj.dominant = 1;

          if (hashParts.dominantOS == "1") osObj.dominant = 1;
        }
      }
      if (!odObj && !osObj) return null;
      return { od: odObj, os: osObj };
    },

    addRx: function (lensRx1, lensRx2, rotation = 0) {
      // console.log("addRx");
      if (!lensRx1 && !lensRx2) return null;
      if (lensRx1 && !lensRx2) return lensRx1;
      if (!lensRx1 && lensRx2) return lensRx2;
      // console.log(lensRx1);
      var ansObj = this.newRxObject();
      var clSph = this.sphP(lensRx1);
      var clCyl = this.cylP(lensRx1);
      var clAxis = this.axisP(lensRx1) + rotation;
      var orSph = this.sphP(lensRx2);
      var orCyl = this.cylP(lensRx2);
      var orAxis = this.axisP(lensRx2);
      var resSph = 0;
      var resCyl = 0;
      var resAxis = 0;

      //the following was added 12/04/2011
      //if either lens has 0 cyl (is spherical) set the axis to match the other lens
      if (clCyl === 0) clAxis = orAxis;
      if (orCyl === 0) orAxis = clAxis;
      var axisDiff = orAxis - clAxis;

      resAxis =
        Math.atan(
          (Math.sin(this.radians(axisDiff * 2)) * orCyl) /
            (Number(clCyl) + orCyl * Math.cos(this.radians(axisDiff * 2)))
        ) / 2;
      resAxis = this.degrees(resAxis);

      if (axisDiff !== 0) {
        resCyl =
          (orCyl * Math.sin(this.radians(2 * axisDiff))) /
          Math.sin(this.radians(2 * resAxis));
        resSph = (Number(clCyl) + Number(orCyl) - resCyl) / 2;
        resAxis += Number(clAxis);
        resSph += Number(clSph) + Number(orSph);

        if (orCyl === 0 || clCyl === 0) {
          ansObj.sph = orSph + Number(clSph);
          ansObj.cyl = orCyl + Number(clCyl);
          if (orCyl === 0) {
            ansObj.axis = clAxis;
          }
          if (clCyl === 0) {
            ansObj.axis = orAxis;
          }
        } else {
          ansObj.sph = resSph;
          ansObj.cyl = resCyl;
          ansObj.axis = resAxis;
        }
      } else {
        //axisDiff = 0 (cl rx and overrefraction were at same axis)
        ansObj.sph = Number(clSph) + Number(orSph);
        ansObj.cyl = Number(clCyl) + Number(orCyl);
        ansObj.axis = clAxis;
      }
      ansObj.axis = this.fixAxis(ansObj.axis - rotation);

      return ansObj;
    },

    subtractRx: function (lensRx1, lensRx2) {
      //lensRx1 & lensRx2 are Rx objects.  lensRx1 is usually the MR, lensRx2 is the spectacles

      var newAxis = 0;
      var newSph = 0;
      var gamma = 0;
      var F2 = 0;
      var xSph = this.sphP(lensRx1);
      var xCyl = this.cylP(lensRx1);
      var xAxis = this.axisP(lensRx1);

      if (this.cylP(lensRx2) > 0.375) {
        var theta = xAxis - this.axisP(lensRx2);
        if (theta > 90) {
          theta = 180 - theta;
        }
        if (theta < -90) {
          theta = -180 - theta;
        }

        var a = xCyl * Math.sin(this.radians(2 * theta));
        var b = xCyl * Math.cos(this.radians(2 * theta)) - this.cylP(lensRx2);
        F2 = Math.sqrt(a * a + b * b);

        if (F2 !== 0) {
          gamma = this.degrees(Math.asin(a / F2) / 2);
        }
        newAxis = this.axisP(lensRx2) + parseFloat(gamma);
        var lens2sphP = this.sphP(lensRx2);
        var lens2cylP = this.cylP(lensRx2);
        newSph = xSph - lens2sphP - (lens2cylP + F2 - xCyl) / 2;
      } else {
        newSph = xSph - this.sphP(lensRx2);
        F2 = xCyl;
        newAxis = xAxis;
      }

      // added 2015-02-18: Without this the axis would be off by 90 deg if the 2nd lens had more cyl than the first
      if (lens2cylP > xCyl) newAxis += 90;

      var tempObj = this.newRxObject();
      tempObj.sph = newSph;
      tempObj.cyl = F2;
      tempObj.axis = newAxis;

      return tempObj;
    },

    tearLensPower: function (kObj, flatBC, steepBC) {
      let flatK = this.flatK(kObj);
      let steepK = this.steepK(kObj);
      let axis = this.flatKmeridian(kObj);
      let sph = flatBC - flatK;
      let cyl = steepBC - steepK - (flatBC - flatK);
      let tearLensRx = this.setRxObjectParameters(null, {
        sph: sph,
        cyl: cyl,
        axis: axis,
      });
      return tearLensRx;
    },

    // ------- keratometry-specific functions ------

    checkMMRange: function (aNum) {
      aNum = parseFloat(aNum);
      //if (isNaN(aNum) ) return false;
      return aNum <= 11.0 && aNum >= 4.8;
    },

    checkKRange: function (aNum) {
      aNum = parseFloat(aNum);
      // if (isNaN(aNum) ) return false;
      return aNum <= 70 && aNum >= 30; // && !isNaN(aNum);
    },

    checkValidKvalue: function (aNum) {
      return this.checkMMRange(aNum) || this.checkKRange(aNum);
    },

    kValueMM: function (kValue) {
      kValue = parseFloat(kValue);
      if (!this.checkValidKvalue(kValue)) return false;
      return this.checkMMRange(kValue) ? kValue : 337.5 / kValue;
    },

    kValueD: function (kValue) {
      kValue = parseFloat(kValue);
      if (!this.checkValidKvalue(kValue)) return false;
      return this.checkKRange(kValue) ? kValue : 337.5 / kValue;
    },

    prettyKstring: function (kValue, showUnit) {
      console.log(kValue);
      return this.checkKRange(kValue)
        ? this.prettyKstringDiopters(kValue, showUnit)
        : this.prettyKstringMM(kValue, showUnit);
    },

    //if K value is in diopters, returns a string in mm (and vice versa)
    prettyKstringConverted: function (kValue, showUnit) {
      let isDiopters = this.checkKRange(kValue);
      let kString = isDiopters
        ? this.prettyKstringMM(kValue, showUnit)
        : this.prettyKstringDiopters(kValue, showUnit);
      return kString;
    },

    prettyKstringDiopters: function (kValue, showUnit) {
      if (!this.checkValidKvalue(kValue)) return "";
      let unit = showUnit ? "D" : "";
      let diopterValue = this.kValueD(kValue);
      let kString = this.numToDiopterString(diopterValue, 0, 0.125) + unit;
      return kString;
    },

    prettyKstringMM: function (kValue, showUnit) {
      if (!this.checkValidKvalue(kValue)) return "";
      let unit = showUnit ? "mm" : "";
      let mmValue = this.kValueMM(kValue);
      let kString = mmValue.toFixed(2) + unit;
      return kString;
    },

    prettyKstringDandMM: function (kValue, showUnits = false, first = "mm") {
      let dString = this.prettyKstringDiopters(kValue, showUnits);
      let mmString = this.prettyKstringMM(kValue, showUnits);
      return first == "D"
        ? `${dString} (${mmString})`
        : `${mmString} (${dString})`;
    },

    // ---------------------- full k stuff ----------------------

    // sets rxObject's properties to the props described in parameterObj
    // if any props not set (and don't already exist), uses defaults
    setKeratometryParameters: function (kObject, parameterObj) {
      if (!Object) kObject = {};
      if (!parameterObj) parameterObj = {};
      let parameterDefaults = {};
      parameterDefaults.k1 = 0;
      parameterDefaults.k2 = 0;
      parameterDefaults.k2meridian = 90;
      let mergedObject = { ...parameterDefaults, ...kObject, ...parameterObj };
      return mergedObject;
    },

    // convenience function
    newKeratometryObject: function () {
      return this.setKeratometryParameters();
    },

    // formats in diopters or mm, depending on how it was provided
    prettyFullKstring: function (kObject) {
      // console.log(kObject);
      let rtnString = "";
      if (!kObject || !kObject.k1) return "";
      if (this.checkMMRange(kObject.k1))
        rtnString = this.prettyFullKstringMM(kObject);
      if (this.checkKRange(kObject.k1))
        rtnString = this.prettyFullKstringDiopters(kObject);
      return rtnString;
    },

    // formats diopters or mm, uses opposite values from what was provided
    prettyFullKstringConverted: function (kObject) {
      let rtnString = "";
      if (this.checkKRange(kObject.k1))
        rtnString = this.prettyFullKstringMM(kObject);
      if (this.checkMMRange(kObject.k1))
        rtnString = this.prettyFullKstringDiopters(kObject);
      return rtnString;
    },

    prettyFullKstringDiopters: function (kObject) {
      let returnString;

      //if spherical cornea:
      if (this.isSphericalCornea(kObject)) {
        returnString = this.prettyKstringDiopters(kObject.k1, true);
      } else {
        returnString =
          this.prettyKstringDiopters(kObject.k1, false) +
          " / " +
          this.prettyKstringDiopters(kObject.k2, false) +
          " @ " +
          this.numToAxisString(kObject.k2meridian);
      }
      return returnString;
    },

    prettyFullKstringMM: function (kObject) {
      let returnString;

      //if spherical cornea:
      if (this.isSphericalCornea(kObject)) {
        returnString = this.prettyKstringMM(kObject.k1, true);
      } else {
        returnString =
          this.prettyKstringMM(kObject.k1, false) +
          " / " +
          this.prettyKstringMM(kObject.k2, false) +
          " @ " +
          this.numToAxisString(kObject.k2meridian);
      }
      return returnString;
    },

    prettyFullKstringDandMM: function (kObject) {
      let dString = this.prettyFullKstringDiopters(kObject);
      let mmString = this.prettyFullKstringMM(kObject);
      if (!dString || !mmString) return "";
      return `${dString} (${mmString})`;
    },

    prettyFullStringOU: function (kObjects) {
      let rtn = "";
      if (kObjects?.od) rtn += this.prettyFullKstring(kObjects.od);
      rtn += " ";
      if (kObjects?.os) rtn += this.prettyFullKstring(kObjects.os);
      return rtn;
    },

    isSphericalCornea: function (kObject) {
      return this.cornealCyl(kObject) < 0.12;
    },

    cornealCyl: function (kObject) {
      return Math.abs(kObject.k1 - kObject.k2);
    },

    // convenience function (to use the same nomenclature as the RxObject)
    parseKstring: function (kString) {
      return this.fullKStringBreaker(kString);
    },
    fullKStringBreaker: function (fullKString) {
      //the first two are possibilities if something is pasted into the field from an EMR
      let reg1 = new RegExp(
        /(\d\d(?:\.\d{0,3})?)\s+(\d{1,3})\s+(\d\d(?:\.\d{0,3})?)\s+(\d{1,3})/
      ); //42 180 44 90
      let regp2 = new RegExp(
        /(\d\d(?:\.\d{0,3})?)\s+(\d\d(?:\.\d{0,3})?)\s+(\d{1,3})/
      ); // 42 44 90
      let test;
      if (regp2.test(fullKString)) {
        test = fullKString.match(regp2);
        fullKString = test[1] + "@" + test[2] + "/" + test[3] + "@" + test[4];
      }

      if (reg1.test(fullKString)) {
        test = fullKString.match(reg1);
        fullKString = test[1] + "/" + test[2] + "@" + test[3];
      }

      fullKString = this.removeAllButFullKeratoChars(fullKString);

      if (fullKString.length < 1) return null;

      let returnKeratoObj = this.newKeratometryObject();
      let k1 = 0;
      let k2 = 0;
      let m2 = 90;

      //check if one number is entered eg) 43.00 may be a spherical cornea.  also check for "/" and "" signs

      reg1 = new RegExp(
        /^(\d\d?(?:\.\d{0,3})?)\/(\d\d?(?:\.\d{0,3})?)@(\d{1,3})$/
      ); //  42/43@90
      if (reg1.test(fullKString)) {
        test = fullKString.match(reg1);
        k1 = parseFloat(test[1]);
        k2 = parseFloat(test[2]);
        m2 = parseInt(test[3], 10);
      }

      let reg2 = new RegExp(
        /^(\d\d?(?:\.\d{0,3})?)@(\d{1,3})\/(\d\d?(?:\.\d{0,3})?)@(\d{1,3})$/
      ); //  42@180/43@90 regex
      if (reg2.test(fullKString)) {
        test = fullKString.match(reg2);
        k1 = parseFloat(test[1]);
        k2 = parseFloat(test[3]);
        m2 = parseInt(test[4], 10);
      }

      let reg2a = new RegExp(/^(\d\d?(?:\.\d{0,3})?)@(\d{1,3})\/?$/); //  42@180/ regex
      if (reg2a.test(fullKString)) {
        test = fullKString.match(reg2a);
        k1 = parseFloat(test[1]);
        m2 = parseInt(test[2], 10) + 90;
      }

      let reg2b = new RegExp(
        /^(\d\d?(?:\.\d{0,3})?)@(\d{1,3})\/(\d\d?(?:\.\d{0,3})?)@?$/
      ); //  42@180/43@
      if (reg2b.test(fullKString)) {
        test = fullKString.match(reg2b);
        k1 = parseFloat(test[1]);
        k2 = parseFloat(test[3]);
        m2 = parseInt(test[2], 10) + 90;
      }

      let reg3 = new RegExp(/^(\d\d?(?:\.\d{0,3})?)\/(\d\d?(?:\.\d{0,3})?)@?$/); //  42/44 regex
      if (reg3.test(fullKString)) {
        test = fullKString.match(reg3);
        k1 = parseFloat(test[1]);
        k2 = parseFloat(test[2]);
      }

      let reg4 = new RegExp(/^(\d\d?(?:\.\d{0,3})?)\/?@?$/); //  42 regex
      if (reg4.test(fullKString)) {
        test = fullKString.match(reg4);
        k1 = parseFloat(test[1]);
        k2 = parseFloat(test[1]);
      }

      //if (k1 < 10) k1 *= 10; // make 4 = 40, 5 = 50, etc;
      //if (k2 < 10) k2 *= 10;

      if (this.checkValidKvalue(k1)) {
        returnKeratoObj.k1 = k1;
      } else {
        returnKeratoObj = null;
      }
      if (this.checkValidKvalue(k2)) {
        returnKeratoObj.k2 = k2;
      } else if (this.checkValidKvalue(k1)) {
        returnKeratoObj.k2 = k1;
      }

      if (!returnKeratoObj) return null; // is this needed?

      if (m2 >= 0 && m2 <= 180) {
        returnKeratoObj.k2meridian = m2;
      } else {
        returnKeratoObj.k2meridian = 90;
      }

      return returnKeratoObj;
    }, // end full keratometry parser

    removeAllButFullKeratoChars: function (keratoString) {
      if (!keratoString) return "";
      let digits = keratoString.replace(/[^0-9.-/@]/g, "");
      return digits;
    },

    isValidFullK: function (kObject) {
      if (!kObject) return false;
      let okK1 = this.checkValidKvalue(kObject.k1);
      let okK2 = this.checkValidKvalue(kObject.k2);
      //let okMeridian1 = this.checkAxisRange(kObject.m1);
      //let okMeridian2 = this.checkAxisRange(kObject.m2);
      return okK1 && okK2; // && okMeridian1 && okMeridian2);
    },

    cylType: function (kObject) {
      let theType = "";

      if (kObject.k2 > kObject.k1) {
        if (kObject.k2meridian >= 60 && kObject.k2meridian <= 150)
          theType = "WTR";
        if (kObject.k2meridian < 60 || kObject.k2meridian > 150)
          theType = "ATR";
      } else {
        if (kObject.k2meridian >= 60 && kObject.k2meridian <= 150)
          theType = "ATR";
        if (kObject.k2meridian < 60 || kObject.k2meridian > 150)
          theType = "WTR";
      }

      if (
        (kObject.k2meridian > 30 && kObject.k2meridian < 60) ||
        (kObject.k2meridian > 120 && kObject.k2meridian < 150)
      )
        theType = "oblique";

      if (Math.abs(kObject.k1 - kObject.k2) < 0.25) theType = "spherical";

      return theType;
    },

    steepK: function (kObject) {
      if (kObject.k1 < kObject.k2) return kObject.k2;
      return kObject.k1;
    },

    flatK: function (kObject) {
      if (kObject.k1 < kObject.k2) return kObject.k1;
      return kObject.k2;
    },

    flatKmeridian: function (kObject) {
      if (kObject.k1 < kObject.k2) return this.fixAxis(kObject.k2meridian + 90);
      return kObject.k2meridian;
    },

    steepKmeridian: function (kObject) {
      if (kObject.k1 > kObject.k2) return this.fixAxis(kObject.k2meridian + 90);
      return kObject.k2meridian;
    },

    horizKmeridian: function (kObject) {
      //console.log("horizKmeridian", kObject);
      if (kObject.k2meridian > 45 && kObject.k2meridian <= 135)
        return this.fixAxis(kObject.k2meridian + 90);
      return kObject.k2meridian;
    },

    vertKmeridian: function (kObject) {
      if (kObject.k2meridian <= 45 || kObject.k2meridian > 135)
        return this.fixAxis(kObject.k2meridian + 90);
      return kObject.k2meridian;
    },

    horizontalKpower: function (kObject) {
      if (kObject.k2meridian > 45 && kObject.k2meridian <= 135)
        return kObject.k1;
      return kObject.k2;
    },

    verticalKpower: function (kObject) {
      if (kObject.k2meridian >= 45 && kObject.k2meridian < 135)
        return kObject.k2;
      return kObject.k1;
    },

    midK: function (kObject) {
      if (!kObject) return null;
      return kObject.k1 / 2 + kObject.k2 / 2;
    },
    keratometryHash: function (kObject, eye, kKey = "keratometry") {
      if (this.isObjectEmpty(kObject)) return "";
      if (!eye) eye = "";
      let k = parseFloat(kObject.k1) + "/";
      k += parseFloat(kObject.k2) + "@";
      k += parseInt(kObject.k2meridian);
      return kKey + eye.toUpperCase() + "=" + encodeURIComponent(k);
    },
    keratometryHashOU: function (kObjects, kKey = "keratometry") {
      let rtn = [];
      let od = null;
      let os = null;
      if (kObjects.od) od = this.keratometryHash(kObjects.od, "od", kKey);
      if (kObjects.os) os = this.keratometryHash(kObjects.os, "os", kKey);

      if (!od && !os) return "";
      if (od) rtn.push(od);
      if (os) rtn.push(os);
      let str = rtn.join("&");
      return str;
    },

    // the inverse of the above function
    // takes hashparts (hashParserMixin) and returns RxObjects
    keratometryFromHash: function (hashParts, kKey = "keratometry") {
      // console.log(kKey);
      let odObj = null;
      let osObj = null;
      if (!hashParts) return null;
      if (hashParts[kKey + "OD"])
        odObj = this.fullKStringBreaker(hashParts[kKey + "OD"]);
      if (hashParts[kKey + "OS"])
        osObj = this.fullKStringBreaker(hashParts[kKey + "OS"]);

      if (!odObj && !osObj) return null;
      return { od: odObj, os: osObj };
    },

    // --- VA stuff ----
    VAFromCorrection: function (rxObject) {
      let bcva = 15;
      let sphEq = Math.abs(this.sphericalEquivalent(rxObject));
      let theCyl = this.cylP(rxObject);
      let VA = 80;

      if (sphEq <= 1.5) {
        VA = theCyl * 21 + 10 + (45 - theCyl * 4) * sphEq;
      } else {
        VA = theCyl * 21 + 10 + (42 - theCyl * 4) * sphEq + 90 * (sphEq - 1.5);
      }

      VA = bcva * ((bcva + VA) / (bcva + 10));

      //another little adjustment. why?  why not?
      if (sphEq > 2) VA *= 1.3;

      return VA;
    },

    vaForSphere: function (sph) {
      return sph ** 1.7 * 32;
    },

    VAFromCorrection2: function (rxObject, bcva = 15) {
      // console.log(rxObject);
      let vPwr = Math.abs(this.verticalPower(rxObject));
      let hPwr = Math.abs(this.horizontalPower(rxObject));
      let diff = Math.abs(vPwr - hPwr);

      let maxPwr = Math.max(vPwr, hPwr);
      let minPwr = Math.min(vPwr, hPwr);
      // console.log(this.vaForSphere(minPwr));
      // console.log(this.vaForSphere(maxPwr));
      let VA =
        bcva + this.vaForSphere(minPwr) * 0.7 + this.vaForSphere(maxPwr) * 0.3;
      if (diff > 0.2) VA += 5;
      return parseInt(VA);
    },

    eyeChartVA: function (VA) {
      let chartArray = [
        15, 20, 25, 30, 40, 50, 60, 80, 100, 150, 200, 300, 400,
      ];

      //find the closest value
      let closest = null;
      chartArray.forEach((item) => {
        if (closest == null || Math.abs(VA - closest) > Math.abs(item - VA))
          closest = item;
      });

      let rtn = "20/" + closest;

      //a little tweaking by adding "+" or "-" where appropriate
      if (VA < 15) {
        // do nothing - we don't want "20/15+"
      } else if (VA <= 35) {
        rtn += VA - closest > 2 ? "-" : "";
        rtn += VA - closest < 2 ? "+" : "";
      } else if (VA < 70) {
        rtn += VA - closest > 4 ? "-" : "";
        rtn += VA - closest < 4 ? "+" : "";
      } else if (VA > 450) {
        rtn = "< 20/400";
      }
      return rtn;
    },

    // ----- utilities -----
    radians: function (degrees) {
      return (degrees * Math.PI) / 180;
    },

    degrees: function (radians) {
      return (radians * 180) / Math.PI;
    },

    isObjectEmpty: function (obj) {
      return !obj || Object.keys(obj).length === 0;
    },
  }, // end methods
}; // end export
