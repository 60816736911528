export default {
  data: () => ({
    iconColorClass: { od: "green darken-2", os: "blue darken-2" },
    textColorClass: {
      od: "text--darken-2 green--text",
      os: "text--darken-2 blue--text",
    },
    bkgColorClass: {
      od: "green lighten-5 ",
      os: "blue lighten-5",
    },
    orangeBtnColor: "#ffc46c",
  }),

  methods: {
    percentageToHsl: function (percentage, hue0, hue1) {
      var hue = percentage * (hue1 - hue0) + hue0;
      return "hsl(" + hue + ", 100%, 35%)";
    },
    vaStyle(vaNum) {
      let percent = (50 - vaNum) / 35; //20/15 will be 1, 20/50 will be 0
      percent = Math.max(percent, 0);
      return (
        "font-weight:bold !important; color: " +
        this.percentageToHsl(percent, 0, 120)
      );
    },
  }, // end methods
  computed: {},
}; // end export
